import React, { FC, useEffect } from 'react';
import { Container, Row, Col } from 'react-bootstrap';

import { AmplitudeEvents, sendAmplitudeEvent } from '../../utils/amplitude';

import './Terms.css';

export const Terms: FC = () => {
  useEffect(() => {
    sendAmplitudeEvent(AmplitudeEvents.TERMS_VIEWED);
  }, []);

  return (
    <section className="Terms">
      <Container>
        <Row>
          <Col>
            <h3>Terms & Conditions</h3>
            <h5>1. General provisions</h5>
            <p>
              1.1. These Terms of Use (hereinafter referred to as the &quot;Terms&quot;) govern the relationship between the User and the owner of the Dubai AI project (hereinafter referred to as the “Company”) in connection with the use of the Dubai AI tool for predicting real estate prices in Dubai.
            </p>
            <p>
              1.2. Use of the Dubai AI tool constitutes the User&apos;s full and unconditional acceptance of all provisions of these Terms. If the User does not agree with any of the provisions of these Terms, he must refrain from using the tool.
            </p>
            <h5>2. Services</h5>
            <p>
              2.1. &quot;Dubai AI&quot; provides the User with the opportunity to receive a real estate price prediction based on the entered parameters and location.
            </p>
            <p>
              2.2. The predictions and explanations provided by the Dubai AI tool are based on data processing using machine learning techniques and are provided &quot;as is&quot;. The company is not responsible for possible deviations of the predicted price from the actual value of the property.
            </p>
            <h5>3. Terms of service</h5>
            <p>
              3.1. The user can use the tool for free to get three predictions. Further provision of the service is carried out on a paid basis.
            </p>
            <p>
              3.2. To purchase paid predictions, the User must create an account and pay for the corresponding package of predictions using the payment methods offered on the site.
            </p>
            <p>
              3.3. Refunds for unused predictions are not provided, except in cases provided for by current legislation.
            </p>
            <h5>4. License agreement</h5>
            <p>
              4.1. The Company grants the User a limited, non-exclusive, non-transferable license to use the Dubai AI tool solely for personal use, in accordance with these Terms.
            </p>
            <p>
              4.2. The User may not modify, copy, distribute, reproduce or use the Dubai AI tool for commercial purposes without the prior written permission of the Company.
            </p>
            <h5>5. Limitation of liability</h5>
            <p>
              5.1. The Company is not responsible for the use or inability to use the Dubai AI tool, as well as for any damages, direct or indirect, that the User may incur in connection with the use or inability to use the tool.
            </p>
            <p>
              5.2. The Company is not responsible for financial losses, lost profits, or any other types of damages caused by the use of predictions or data obtained through the Dubai AI tool.
            </p>
            <h5>6. Personal data</h5>
            <p>
              6.1. The Company undertakes to ensure the confidentiality of the User&apos;s personal data in accordance with current legislation and the privacy policy, which is an integral part of these Terms.
            </p>
            <p>
              6.2. The user agrees to the processing of his personal data to the extent necessary to provide the service.
            </p>
            <h5>7. Copyright and Intellectual Property</h5>
            <p>
              7.1. All rights to the Dubai AI tool, including, but not limited to, source code, machine learning algorithms, databases, interfaces, designs, graphics, logos, trademarks, and all related technologies, belong to the Company and are protected by international copyright laws and other intellectual property laws.
            </p>
            <p>
              7.2. The User may not copy, modify, distribute, publish, reproduce or create derivative works based on the materials, technologies or data used or generated by the Dubai AI tool without the prior written permission of the Company.
            </p>
            <p>
              7.3. All data used for training and operation of the model was obtained legally and is processed in accordance with current legislation. The Company has all the necessary rights to use such data as part of the provision of services through the Dubai AI tool.
            </p>
            <p>
              7.4. Any unauthorized use of technology, data, and materials associated with the Dubai AI tool will be considered a violation of intellectual property rights and may result in civil, administrative or criminal liability in accordance with the law.
            </p>
            <p>
              7.5. In the event of discovery of intellectual property rights violations related to the use of the Dubai AI tool, the Company reserves the right to take all necessary measures to protect its rights, including, but not limited to, filing claims for damages and suppression of misuse.
            </p>
            <p>
              7.6. The User undertakes to respect all intellectual property rights owned by the Company and not to take actions that may lead to the violation of such rights.
            </p>
            <h5>8. Update and support</h5>
            <p>
              8.1. The company reserves the right to periodically update the Dubai AI tool, improve its functionality, and also temporarily suspend the operation of the tool for technical work.
            </p>
            <p>
              8.2. The Company is not responsible for any inconvenience caused by the temporary unavailability of the Dubai AI tool due to maintenance or updates.
            </p>
            <h5>9. Dispute resolution procedure</h5>
            <p>
              9.1. In case of disputes related to the use of the Dubai AI tool, the parties undertake to take all possible measures to resolve them through negotiations.
            </p>
            <p>
              9.2. If it is impossible to resolve the dispute through negotiations, the dispute is subject to judicial review in accordance with the legislation of the Russian Federation.
            </p>
            <p>
              9.3. Before filing a claim, the User must send a claim to the Company, to which the Company undertakes to respond within 30 (thirty) calendar days.
            </p>
            <h5>10. Applicable law and jurisdiction</h5>
            <p>
              10.1. These Terms are governed by and construed in accordance with the laws of the Russian Federation in which the Company is registered.
            </p>
            <p>
              10.2. All disputes arising in connection with these Terms are subject to consideration in court at the location of the Company, unless otherwise provided by the current legislation of the Russian Federation.
            </p>
            <h5>11. Financial activities</h5>
            <p>
              11.1. All payments for the use of paid services of the Dubai AI tool are made within the framework of the self-employment regime provided for by the current legislation of the Russian Federation. Financial activities are carried out by an individual - self-employed: Yakovlev Denis Viktorovich, TIN: 503240742904 (Яковлев Денис Викторович, ИНН: 503240742904).
            </p>
            <p>
              11.2. The User understands and agrees that documents confirming payment for services will be generated within the framework of the self-employment regime, and undertakes to accept them in accordance with the legislation of the Russian Federation.
            </p>
            <h5>12. Final provisions</h5>
            <p>
              12.1. These Terms may be changed and supplemented by the Company unilaterally. The User will be notified of all changes on the website or by email specified during registration.
            </p>
            <p>
              12.2. The amended Terms come into force from the moment they are published on the website. Continued use of the Dubai AI tool after the publication of changes means the User agrees to the new Terms.
            </p>
            <p>
              12.3. If any provision of these Terms is found to be invalid or unenforceable, this will not affect the validity of the remaining provisions of the Terms.
            </p>
            <h5>13. Contact information</h5>
            <p>
              13.1. The User can contact the Company with any questions related to the use of the &quot;Dubai AI&quot; tool using the following contact information: quvedevelopment@ya.ru.
            </p>
          </Col>
        </Row>
      </Container>
    </section>
  );
};
