import React, { FC, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Container,
  Row,
  Col,
  Button,
} from 'react-bootstrap';

import { FlowHeader } from '../../../components/Flow/FlowHeader/FlowHeader';
import { Skeleton } from '../../../components/Skeleton/Skeleton';
import { Paths } from '../../../constants';
import { useAppSelector } from '../../../store';
import {
  selectUserEmail,
  selectUserId,
} from '../../../store/user.reducer';
import { AmplitudeEvents, sendAmplitudeEvent } from '../../../utils/amplitude';
import {
  selectAvailableFreePredictions,
  selectAvailablePaidPredictions,
  selectIsLimitsRequesting,
} from '../../../store/limits.reducer';

import './Profile.css';

export const Profile: FC = () => {
  const navigate = useNavigate();

  const id = useAppSelector(selectUserId);
  const email = useAppSelector(selectUserEmail);
  const isLimitsRequesting = useAppSelector(selectIsLimitsRequesting);
  const availableFreePredictions = useAppSelector(selectAvailableFreePredictions);
  const availablePaidPredictions = useAppSelector(selectAvailablePaidPredictions);

  useEffect(() => {
    sendAmplitudeEvent(AmplitudeEvents.SELECT_PROJECT_VIEWED);
  }, []);

  return (
    <div className="Profile">
      <Container className="Profile__section">
        <Row>
          <Col lg={4} />
          <Col lg={4}>
            <FlowHeader
              text="💎 Account"
              className="mb-3"
            />
            <div className="Profile__info">
              <h4 className="Profile__infoBlockTitle">
                Details
              </h4>
              <div className="Profile__infoBlockParameters">
                <div className="Profile__infoBlockParametersItem">
                  <div className="Profile__infoBlockParametersItemKey">Email:</div>
                  <div className="Profile__infoBlockParametersItemValue">{ email }</div>
                </div>
                <div className="Profile__infoBlockParametersItem">
                  <div className="Profile__infoBlockParametersItemKey">ID:</div>
                  <div className="Profile__infoBlockParametersItemValue">{ id }</div>
                </div>
              </div>
            </div>
            <div className="Profile__info">
              <h4 className="Profile__infoBlockTitle">
                Limits
              </h4>
              <div className="Profile__infoBlockParameters mb-3">
                <div className="Profile__infoBlockParametersItem">
                  <div className="Profile__infoBlockParametersItemKey">Free predictions:</div>
                  <div className="Profile__infoBlockParametersItemValue">
                    { isLimitsRequesting ? <Skeleton /> : availableFreePredictions }
                  </div>
                </div>
                <div className="Profile__infoBlockParametersItem">
                  <div className="Profile__infoBlockParametersItemKey">Paid predictions:</div>
                  <div className="Profile__infoBlockParametersItemValue">
                    { isLimitsRequesting ? <Skeleton /> : availablePaidPredictions }
                  </div>
                </div>
              </div>
              <Button onClick={() => navigate(Paths.PAYMENT_PACKS)}>Upgrade Limits</Button>
            </div>
          </Col>
          <Col lg={4} />
        </Row>
      </Container>
    </div>
  );
};
