import React from 'react';
import {
  Container,
  Row,
  Col,
  Button,
} from 'react-bootstrap';

import heroImage from '../../../assets/hero-image.png';

interface HeroProps {
  onGoToSignUp: (placement: string) => void;
}

const Hero: React.FC<HeroProps> = ({
  onGoToSignUp,
}) => {
  return (
    <section className="Hero">
      <Container className="Hero__section">
        <Row>
          <Col lg={6}>
            <div className="Hero__content">
              <h5 className="Hero__contentHeadline">Predict the Price of a Property in Dubai</h5>
              <h1 className="Hero__contentTitle">
                <span>We make you understand</span>
                <br />
                <span style={{ color: 'var(--color-accent)' }}>the real price</span>
                <br />
                <span>of your new home</span>
              </h1>
              <p className="Hero__contentText">
                Our ML-models allow us to predict the current price of real estate in Dubai.
                <br />
                Models work on big data and take into account many parameters.
              </p>
              <Button variant="dark" size="lg" onClick={() => onGoToSignUp('Hero')}>Get Prediction</Button>
            </div>
          </Col>
          <Col lg={6}>
            <div className="Hero__image">
              <img alt="Dubai AI Interface" src={heroImage} />
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default Hero;
