import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';

import { AppState } from '.';
import { getPropertyInfo } from '../api/property';
import { propertyAdjustmentsInitialState, PropertyAdjustmentsState } from './property.adjustments.reducer';

export interface PropertyInfo {
  id: number | null;
  masterProject: string,
  projectName: string,
  buildingName: string,
  walkingDistanceToMetro: number | null,
  drivingDistanceToBeach: number | null,
  walkingDistanceToBeach: number | null,
  drivingDistanceToDowntown: number | null,
  walkingDistanceToDowntown: number | null,
  drivingDistanceToBbay: number | null,
  walkingDistanceToBbay: number | null,
  drivingDistanceToHealthcare: number | null,
  walkingDistanceToHealthcare: number | null,
  drivingDistanceToDfountain: number | null,
  walkingDistanceToDfountain: number | null
}

export interface PropertyInfoState {
  isRequesting: boolean;
  info: PropertyInfo;
  predictionParameters: PropertyAdjustmentsState;
}

export const propertyInfoInitialState: PropertyInfoState = {
  isRequesting: false,
  info: {
    id: null,
    masterProject: '',
    projectName: '',
    buildingName: '',
    walkingDistanceToMetro: null,
    drivingDistanceToBeach: null,
    walkingDistanceToBeach: null,
    drivingDistanceToDowntown: null,
    walkingDistanceToDowntown: null,
    drivingDistanceToBbay: null,
    walkingDistanceToBbay: null,
    drivingDistanceToHealthcare: null,
    walkingDistanceToHealthcare: null,
    drivingDistanceToDfountain: null,
    walkingDistanceToDfountain: null,
  },
  predictionParameters: propertyAdjustmentsInitialState,
};

const propertyInfoSlice = createSlice({
  name: 'propertyInfo',
  initialState: propertyInfoInitialState,
  reducers: {
    setIsPropertyInfoRequesting(state, action: PayloadAction<boolean>) {
      state.isRequesting = action.payload;
    },
    setPropertyInfo(state, action: PayloadAction<PropertyInfo>) {
      state.info = action.payload;
    },
    setPropertyPredictionParameters(state, action: PayloadAction<PropertyAdjustmentsState>) {
      state.predictionParameters = action.payload;
    },
  },
  extraReducers: {},
});

const { reducer } = propertyInfoSlice;
export { reducer as propertyInfoReducer };

export const selectIsPropertyInfoRequesting = (state: AppState) => state.propertyInfo.isRequesting;
export const selectPropertyInfo = (state: AppState) => state.propertyInfo.info;
export const selectPropertyPredictionParameters = (state: AppState) => state.propertyInfo.predictionParameters;

export const {
  setIsPropertyInfoRequesting,
  setPropertyInfo,
  setPropertyPredictionParameters,
} = propertyInfoSlice.actions;

export const getPropertyInfoThunk = createAsyncThunk(
  'propertyInfo/getInfo',
  async ({
    id,
    accessToken,
  } : {
    id: number,
    accessToken: string,
  }, { dispatch }) => {
    dispatch(setIsPropertyInfoRequesting(true));

    const propertyInfoResponse = await getPropertyInfo({ id }, accessToken);

    if (!propertyInfoResponse) {
      return;
    }

    dispatch(setIsPropertyInfoRequesting(false));
    dispatch(setPropertyInfo(propertyInfoResponse));
  },
);
