import React, { FC } from 'react';
import {
  Row,
  Col,
  Container,
} from 'react-bootstrap';

import './SubFooter.css';

export const SubFooter: FC = () => {
  return (
    <section className="SubFooter">
      <Container>
        <Row>
          <Col lg={4}>
            <div className="SubFooter__text">
              Dubai AI &copy;
            </div>
            <div className="SubFooter__text">
              All rights reserved
            </div>
          </Col>
          <Col lg={4}>
            <div className="SubFooter__text text-center">
              Denis Viktorovich Yakovlev • Tax Number: 503240742904
            </div>
            <div className="SubFooter__text text-center">
              Яковлев Денис Викторович • ИНН: 503240742904
            </div>
          </Col>
          <Col lg={4}>
            <div className="SubFooter__text text-end">
              Powered by Quve Development
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
};
