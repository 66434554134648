import React, { FC, useMemo } from 'react';

import './Skeleton.css';

export interface SkeletonProps {
  width?: number;
  isInverted?: boolean;
  opacity?: number;
}

export const Skeleton: FC<SkeletonProps> = ({
  width = '100%',
  isInverted = false,
  opacity = 1,
}) => {
  const skeletonStyle = useMemo(() => {
    return {
      width,
      opacity,
    };
  }, [width]);

  return (
    <div
      className={`Skeleton ${isInverted ? 'Skeleton--inverted' : ''} `}
      style={skeletonStyle}
    >
      &zwnj;
    </div>
  );
};
