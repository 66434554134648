import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';

import { AppState } from '.';
import { getPropertyPricePrediction } from '../api/predict';
import { PropertyPricePredictionRequest, PropertyPriceFreePredictionResponse, PropertyPricePaidPredictionResponse } from '../api/predict/types';
import { setPropertyPredictionParameters } from './property.info.reducer';
import { getLimitsThunk } from './limits.reducer';
import { PredictionTypes } from '../constants';
import { resetPropertySearch } from './property.search.reducer';

export interface PropertyPredictionState {
  isRequesting: boolean;
  type: PredictionTypes;
  price: number | null;
}

export interface PropertyPredictionResponse {
  predictedPrice: number;
}

export const propertyPredictionInitialState: PropertyPredictionState = {
  isRequesting: false,
  type: PredictionTypes.FREE,
  price: null,
};

const propertyPredictionSlice = createSlice({
  name: 'propertyPrediction',
  initialState: propertyPredictionInitialState,
  reducers: {
    setIsPropertyPredictionRequesting(state, action: PayloadAction<boolean>) {
      state.isRequesting = action.payload;
    },
    setPropertyPredictionType(state, action: PayloadAction<PredictionTypes>) {
      state.type = action.payload;
    },
    setPropertyPredictedPrice(state, action: PayloadAction<PropertyPredictionResponse>) {
      state.price = action.payload.predictedPrice;
    },
  },
  extraReducers: {},
});

const { reducer } = propertyPredictionSlice;
export { reducer as propertyPredictionReducer };

export const selectIsPropertyPredictionRequesting = (state: AppState) => state.propertyPrediction.isRequesting;
export const selectPropertyPredictionType = (state: AppState) => state.propertyPrediction.type;
export const selectPropertyPredictedPrice = (state: AppState) => state.propertyPrediction.price;

export const {
  setIsPropertyPredictionRequesting,
  setPropertyPredictionType,
  setPropertyPredictedPrice,
} = propertyPredictionSlice.actions;

export const getPropertyPricePredictionThunk = createAsyncThunk(
  'propertyPrediction/getPrice',
  async ({
    adjustments,
    accessToken,
    onSuccess,
    onFailure,
  } : {
    adjustments: PropertyPricePredictionRequest,
    accessToken: string,
    onSuccess: (price: number, predictionType: PredictionTypes) => void,
    onFailure: () => void,
  }, { dispatch }) => {
    dispatch(setIsPropertyPredictionRequesting(true));

    const propertyPricePredictionResponse = await getPropertyPricePrediction(adjustments, accessToken);

    if (!propertyPricePredictionResponse) {
      dispatch(setIsPropertyPredictionRequesting(false));
      onFailure();
      return;
    }

    if ((propertyPricePredictionResponse as PropertyPriceFreePredictionResponse).limitedPrediction) {
      const prediction = (propertyPricePredictionResponse as PropertyPriceFreePredictionResponse).limitedPrediction;

      dispatch(setPropertyPredictedPrice(prediction));
      dispatch(setPropertyPredictionType(PredictionTypes.FREE));
      onSuccess(prediction.predictedPrice, PredictionTypes.FREE);
    } else {
      const prediction = (propertyPricePredictionResponse as PropertyPricePaidPredictionResponse).fullPrediction;

      dispatch(setPropertyPredictedPrice(prediction));
      dispatch(setPropertyPredictionType(PredictionTypes.PAID));
      onSuccess(prediction.predictedPrice, PredictionTypes.PAID);
    }

    dispatch(setIsPropertyPredictionRequesting(false));
    dispatch(setPropertyPredictionParameters(adjustments));
    dispatch(getLimitsThunk({ accessToken }));
    dispatch(resetPropertySearch());
  },
);
