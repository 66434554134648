import React from 'react';
import { Route, Routes } from 'react-router-dom';

import { Header } from './components/Header/Header';
import { Footer } from './components/Footer/Footer';
import { SubFooter } from './components/SubFooter/SubFooter';
import { ProtectedRoute } from './hocs/ProtectedRoute/ProtectedRoute';
import { Landing } from './views/Landing/Landing';
import { Auth } from './views/AuthFlow/Auth/Auth';
import { Profile } from './views/Account/Profile/Profile';
import { Terms } from './views/Terms/Terms';
import { Packs } from './views/Payment/Packs/Packs';
import { SelectProject } from './views/PredictionFlow/SelectProject/SelectProject';
import { AdjustParameters } from './views/PredictionFlow/AdjustParameters/AdjustParameters';
import { Prediction } from './views/PredictionFlow/Prediction/Prediction';
import { useGetAccessToken } from './hooks/useGetAccessToken';
import { useGetRefreshToken } from './hooks/useGetRefreshToken';
import { useSetUserData } from './hooks/useSetUserId';
import { useGetLimits } from './hooks/useGetLimits';
import { Paths } from './constants';

import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap-icons/font/bootstrap-icons.css';

export const App = () => {
  const accessToken = useGetAccessToken();

  useGetRefreshToken();
  useSetUserData();
  useGetLimits(accessToken);

  return (
    <>
      <Header />
      <Routes>
        <Route path={Paths.MAIN} element={<Landing />} />
        <Route path={Paths.AUTH} element={<Auth />} />
        <Route path={Paths.TERMS} element={<Terms />} />
        <Route
          path={Paths.ACCOUNT_PROFILE}
          element={(
            <ProtectedRoute isAllowed={accessToken !== null} redirectPath={Paths.MAIN}>
              <Profile />
            </ProtectedRoute>
          )}
        />
        <Route
          path={Paths.PAYMENT_PACKS}
          element={(
            <ProtectedRoute isAllowed={accessToken !== null} redirectPath={Paths.MAIN}>
              <Packs />
            </ProtectedRoute>
          )}
        />
        <Route
          path={Paths.PROPERTY_PREDICTION_SELECT_PROJECT}
          element={(
            <ProtectedRoute isAllowed={accessToken !== null} redirectPath={Paths.MAIN}>
              <SelectProject />
            </ProtectedRoute>
          )}
        />
        <Route
          path={Paths.PROPERTY_PREDICTION_ADJUST_PARAMETERS}
          element={(
            <ProtectedRoute isAllowed={accessToken !== null} redirectPath={Paths.MAIN}>
              <AdjustParameters />
            </ProtectedRoute>
          )}
        />
        <Route
          path={Paths.PROPERTY_PREDICTION}
          element={(
            <ProtectedRoute isAllowed={accessToken !== null} redirectPath={Paths.MAIN}>
              <Prediction />
            </ProtectedRoute>
          )}
        />
        <Route path="*" element={<p>There is nothing here: 404!</p>} />
      </Routes>
      <Footer />
      <SubFooter />
    </>
  );
};
