import React, {
  FC,
  useCallback,
  useEffect,
  useState,
} from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Container,
  Row,
  Col,
  Form,
  Button,
  InputGroup,
} from 'react-bootstrap';
import { enqueueSnackbar } from 'notistack';

import { FlowHeadline } from '../../../components/Flow/FlowHeadline/FlowHeadline';
import { FlowHeader } from '../../../components/Flow/FlowHeader/FlowHeader';
import { FlowText } from '../../../components/Flow/FlowText/FlowText';
import {
  Paths,
  PredictionTypes,
  PropertyRegistrationTypes,
  PropertyRoomsTypes,
  PropertyTransactionGroups,
} from '../../../constants';
import { useAppDispatch, useAppSelector } from '../../../store';
import { selectAccessToken } from '../../../store/auth.reducer';
import { selectPropertyAdjustmentsId } from '../../../store/property.adjustments.reducer';
import { getPropertyPricePredictionThunk, selectIsPropertyPredictionRequesting } from '../../../store/property.prediction.reducer';
import { Loading } from '../Loading/Loading';
import { AmplitudeEvents, sendAmplitudeEvent } from '../../../utils/amplitude';

import './AdjustParameters.css';

export const AdjustParameters: FC = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const accessToken = useAppSelector(selectAccessToken);
  const id = useAppSelector(selectPropertyAdjustmentsId);
  const isPropertyPredictionRequesting = useAppSelector(selectIsPropertyPredictionRequesting);
  const [roomsType, setRoomsType] = useState<PropertyRoomsTypes>();
  const [registrationType, setRegistrationType] = useState<PropertyRegistrationTypes>();
  const [transactionGroupType, setTransactionGroupType] = useState<PropertyTransactionGroups>();
  const [area, setArea] = useState<number>(50);
  const [parking, setParking] = useState<boolean>(true);

  useEffect(() => {
    sendAmplitudeEvent(AmplitudeEvents.ADJUST_PARAMETERS_VIEWED);
  }, []);

  const onSumbitHandler = useCallback(() => {
    if (!accessToken) {
      return;
    }

    if (!id) {
      return;
    }

    if (!roomsType) {
      return;
    }

    if (!registrationType) {
      return;
    }

    if (!transactionGroupType) {
      return;
    }

    if (!area) {
      return;
    }

    sendAmplitudeEvent(AmplitudeEvents.ADJUST_PARAMETERS_SUBMITED, {
      id,
      roomsType,
      registrationType,
      transactionGroupType,
      area,
    });
    dispatch(getPropertyPricePredictionThunk({
      adjustments: {
        id,
        transactionGroup: transactionGroupType,
        registrationType,
        rooms: roomsType,
        hasParking: parking,
        procedureArea: area,
      },
      accessToken,
      onSuccess: (price, predictionType) => {
        navigate(Paths.PROPERTY_PREDICTION);
        enqueueSnackbar('Your prediction is ready!');
        sendAmplitudeEvent(AmplitudeEvents.PREDICTION_EXECUTED, {
          pricePerMeter: price,
          type: predictionType === PredictionTypes.FREE ? 'Free' : 'Paid',
        });
      },
      onFailure: () => {},
    }));
  }, [id, roomsType, registrationType, transactionGroupType, area, parking]);

  useEffect(() => {
    if (!id) {
      navigate(Paths.PROPERTY_PREDICTION_SELECT_PROJECT);
    }
  }, [id]);

  if (isPropertyPredictionRequesting) {
    return (
      <Loading />
    );
  }

  return (
    <div className="AdjustParameters">
      <Container className="AdjustParameters__section Flow__section">
        <Row>
          <Col lg={3} />
          <Col lg={6}>
            <FlowHeadline
              text="Step 2/3"
              className="mb-3"
            />
            <FlowHeader
              text="⚙️ Adjust the parameters"
              className="mb-3"
            />
            <FlowText
              text="Select a parameters of real estate:"
            />
            <div className="AdjustParameters__form">
              <Form.Group as={Row} className="mb-3">
                <Form.Label column sm={4}>
                  Number of Rooms:
                </Form.Label>
                <Col sm={8}>
                  <Form.Select
                    value={roomsType}
                    defaultValue="default"
                    required
                    onChange={(e) => setRoomsType(e.target.value as PropertyRoomsTypes)}
                  >
                    <option disabled value="default">Select type of the property...</option>
                    {
                      (Object.entries(PropertyRoomsTypes)).map((item) => {
                        return (
                          <option value={item[1]} key={item[0]}>{ item[0] }</option>
                        );
                      })
                    }
                  </Form.Select>
                </Col>
              </Form.Group>
              <Form.Group as={Row} className="mb-3">
                <Form.Label column sm={4}>
                  Registration Type:
                </Form.Label>
                <Col sm={8}>
                  <Form.Select
                    value={registrationType}
                    defaultValue="default"
                    required
                    onChange={(e) => setRegistrationType(e.target.value as PropertyRegistrationTypes)}
                  >
                    <option disabled value="default">Select type of registration...</option>
                    {
                      (Object.entries(PropertyRegistrationTypes)).map((item) => {
                        return (
                          <option value={item[1]} key={item[0]}>{ item[0] }</option>
                        );
                      })
                    }
                  </Form.Select>
                </Col>
              </Form.Group>
              <Form.Group as={Row} className="mb-3">
                <Form.Label column sm={4}>
                  Transaction Method:
                </Form.Label>
                <Col sm={8}>
                  <Form.Select
                    value={transactionGroupType}
                    defaultValue="default"
                    required
                    onChange={(e) => setTransactionGroupType(e.target.value as PropertyTransactionGroups)}
                  >
                    <option disabled value="default">Select transaction method...</option>
                    {
                      (Object.entries(PropertyTransactionGroups)).map((item) => {
                        return (
                          <option value={item[1]} key={item[0]}>{ item[0] }</option>
                        );
                      })
                    }
                  </Form.Select>
                </Col>
              </Form.Group>
              <Form.Group as={Row} className="mb-3">
                <Form.Label column sm={4}>
                  Area:
                </Form.Label>
                <Col sm={8}>
                  <InputGroup>
                    <Form.Control
                      value={area}
                      type="number"
                      onChange={(e) => setArea(Number(e.target.value))}
                      placeholder="Input area of the property..."
                      required
                    />
                    <InputGroup.Text>m²</InputGroup.Text>
                  </InputGroup>
                  <Form.Control.Feedback type="invalid">
                    Please provide a valid zip.
                  </Form.Control.Feedback>
                </Col>
              </Form.Group>
              <Form.Group as={Row} className="mb-3">
                <Form.Label column sm={4}>
                  Parking:
                </Form.Label>
                <Col sm={8} className="align-content-center">
                  <Form.Check
                    checked={parking}
                    type="switch"
                    label="Include Parking"
                    onChange={(e) => setParking(e.target.checked)}
                  />
                </Col>
              </Form.Group>
              <Button type="submit" onClick={onSumbitHandler}>
                Continue
              </Button>
            </div>
          </Col>
          <Col lg={3} />
        </Row>
      </Container>
    </div>
  );
};
