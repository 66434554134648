import React, { useState, useMemo } from 'react';
import {
  Container,
  Row,
  Col,
  Button,
  InputGroup,
  Form,
} from 'react-bootstrap';

interface PricingProps {
  onGetFreePack: () => void;
  onGetPaidPack: (predictions: number, price: number) => void;
}

const Pricing: React.FC<PricingProps> = ({
  onGetFreePack,
  onGetPaidPack,
}) => {
  const [numberOfPredictions, setNumberOfPredictions] = useState<number>(5);
  const customPrice = useMemo(() => numberOfPredictions * 1.5 - 0.01, [numberOfPredictions]);

  return (
    <section className="Pricing" id="Pricing">
      <Container className="Pricing__section">
        <Row>
          <Col>
            <div className="Pricing__content">
              <h2 className="Pricing__contentTitle">Pricing</h2>
              <p className="Pricing__contentText">
                Flexible Pricing to Suit Your Needs. Just select the number of predictions you are interested in.
              </p>
            </div>
          </Col>
        </Row>
        <Row className="mb-5">
          <Col>
            <div className="Pricing__plan">
              <h6 className="Pricing__planName">Pack of 3 Predictions</h6>
              <h3 className="Pricing__planPrice">Free</h3>
              <div className="Pricing__planFeature">Get 3 Free Predictions</div>
              <div className="Pricing__planFeature Pricing__planFeature--unavailable">Detailed price explanations</div>
              <div className="Pricing__planFeature Pricing__planFeature--unavailable">Full property information</div>
              <div className="Pricing__planFeature Pricing__planFeature--unavailable">Priority customer support</div>
              <Button className="Pricing__planButton" variant="dark" onClick={() => onGetFreePack()}>Start for Free</Button>
              <div className="Pricing__planText">After sign-up, you will be credited with 3 predictions</div>
            </div>
          </Col>
          <Col>
            <div className="Pricing__plan Pricing__plan--accent">
              <h6 className="Pricing__planName">Pack of 10 Predictions</h6>
              <h3 className="Pricing__planPrice">9.99$</h3>
              <div className="Pricing__planFeature">Get 10 Predictions</div>
              <div className="Pricing__planFeature">Detailed price explanations</div>
              <div className="Pricing__planFeature">Full property information</div>
              <div className="Pricing__planFeature">Priority customer support</div>
              <Button className="Pricing__planButton" variant="primary" onClick={() => onGetPaidPack(10, 9.99)}>Get Pack</Button>
              <div className="Pricing__planBadge">Most Popular</div>
              <div className="Pricing__planText">After payment, you will be credited with 10 predictions</div>
            </div>
          </Col>
          <Col>
            <div className="Pricing__plan">
              <h6 className="Pricing__planName">Custom Pack</h6>
              <h3 className="Pricing__planPrice">{`${customPrice}$`}</h3>
              <div className="Pricing__planFeature">Any number of predictions</div>
              <div className="Pricing__planFeature">Detailed price explanations</div>
              <div className="Pricing__planFeature">Full property information</div>
              <div className="Pricing__planFeature">Priority customer support</div>
              <InputGroup className="Pricing__planButton">
                <Form.Control
                  placeholder="Number of predictions"
                  aria-label="Number of predictions"
                  type="number"
                  value={numberOfPredictions}
                  min={1}
                  onChange={(e) => setNumberOfPredictions(Number(e.target.value))}
                />
                <Button variant="dark" onClick={() => onGetPaidPack(numberOfPredictions, customPrice)}>
                  Get Pack
                </Button>
              </InputGroup>
              <div className="Pricing__planText">
                { `After payment, you will be credited with ${numberOfPredictions} predictions` }
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default Pricing;
