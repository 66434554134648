import React, { FC, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Container,
  Row,
  Col,
  Alert,
} from 'react-bootstrap';

import Pricing from '../../Landing/components/Pricing';

import './Packs.css';

export const Packs: FC = () => {
  const navigate = useNavigate();
  const onGetFreePackHandler = useCallback(() => {
    // запрос на получение 3-х бесплатных
  }, []);

  const onGetPaidPackHandler = useCallback((predictions: number, price: number) => {
    // запрос на оплату
  }, []);

  return (
    <Pricing
      onGetFreePack={onGetFreePackHandler}
      onGetPaidPack={onGetPaidPackHandler}
    />
  );
};
