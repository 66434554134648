import React, { FC } from 'react';
import {
  Alert,
} from 'react-bootstrap';

import './LimitBanner.css';

interface LimitBannerProps {
  availableFreePredictions: number;
  availablePaidPredictions: number;
  onGoToUpgradeLimits: () => void;
}

export const LimitBanner: FC<LimitBannerProps> = ({
  availableFreePredictions,
  availablePaidPredictions,
  onGoToUpgradeLimits,
}) => {
  if (availablePaidPredictions > 0) {
    return (
      <Alert variant="light" className="SelectProject__banner">
        <div className="SelectProject__bannerIcon">
          <i className="bi bi-stars pe-2" />
        </div>
        <div className="SelectProject__bannerText">
          <span>{ `You are using paid predictions. Predictions left: ${availablePaidPredictions}` }</span>
          <Alert.Link onClick={onGoToUpgradeLimits}>Upgarde Limits</Alert.Link>
        </div>
      </Alert>
    );
  }

  if (availableFreePredictions > 0) {
    return (
      <Alert variant="light" className="SelectProject__banner">
        <div className="SelectProject__bannerIcon">
          <i className="bi bi-stars pe-2" />
        </div>
        <div className="SelectProject__bannerText">
          <span>{ `You are using free predictions. Predictions left: ${availableFreePredictions}` }</span>
          <Alert.Link onClick={onGoToUpgradeLimits}>Upgarde Limits</Alert.Link>
        </div>
      </Alert>
    );
  }

  return (
    <Alert variant="danger" className="SelectProject__banner">
      <div className="SelectProject__bannerIcon">
        <i className="bi bi-stars pe-2" />
      </div>
      <div className="SelectProject__bannerText">
        <span>You have no more predictions available. Upgrade the limit to continue:</span>
        <Alert.Link onClick={onGoToUpgradeLimits}>Upgarde Limits</Alert.Link>
      </div>
    </Alert>
  );
};
