import { combineReducers, configureStore } from '@reduxjs/toolkit';
import { useDispatch, TypedUseSelectorHook, useSelector } from 'react-redux';

import { authReducer, AuthState } from './auth.reducer';
import { userReducer, UserState } from './user.reducer';
import { limitsReducer, LimitsState } from './limits.reducer';
import { propertySearchReducer, PropertySearchState } from './property.search.reducer';
import { propertyAdjustmentsReducer, PropertyAdjustmentsState } from './property.adjustments.reducer';
import { propertyPredictionReducer, PropertyPredictionState } from './property.prediction.reducer';
import { propertyInfoReducer, PropertyInfoState } from './property.info.reducer';

export interface AppState {
  auth: AuthState,
  user: UserState,
  limits: LimitsState,
  propertySearch: PropertySearchState,
  propertyAdjustments: PropertyAdjustmentsState,
  propertyPrediction: PropertyPredictionState,
  propertyInfo: PropertyInfoState,
}

const rootReducer = combineReducers({
  auth: authReducer,
  user: userReducer,
  limits: limitsReducer,
  propertySearch: propertySearchReducer,
  propertyAdjustments: propertyAdjustmentsReducer,
  propertyPrediction: propertyPredictionReducer,
  propertyInfo: propertyInfoReducer,
});

export const store = configureStore({
  reducer: rootReducer,
  devTools: true,
});

export type RootDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof rootReducer>;

type DispatchFunc = () => RootDispatch;
export const useAppDispatch: DispatchFunc = useDispatch;
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
