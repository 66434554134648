import React, {
  FC,
  useEffect,
  useMemo,
} from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import {
  Container,
  Row,
  Col,
} from 'react-bootstrap';

import { useAppDispatch, useAppSelector } from '../../../store';
import {
  Paths,
  PredictionTypes,
  PropertyRegistrationTypes,
  PropertyRoomsTypes,
  PropertyTransactionGroups,
} from '../../../constants';
import { selectPropertyPredictedPrice, selectPropertyPredictionType } from '../../../store/property.prediction.reducer';
import {
  getPropertyInfoThunk,
  selectIsPropertyInfoRequesting,
  selectPropertyInfo,
  selectPropertyPredictionParameters,
} from '../../../store/property.info.reducer';
import { selectPropertyAdjustmentsId } from '../../../store/property.adjustments.reducer';
import { selectAccessToken } from '../../../store/auth.reducer';
import { getEnumKeyByValue, getFormattedNumber } from '../../../utils/common';
import { AmplitudeEvents, sendAmplitudeEvent } from '../../../utils/amplitude';
import { ContentPlug } from '../../../components/ContentPlug/ContentPlug';
import { Skeleton } from '../../../components/Skeleton/Skeleton';

import './Prediction.css';

export const Prediction: FC = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const accessToken = useAppSelector(selectAccessToken);
  const id = useAppSelector(selectPropertyAdjustmentsId);
  const isPropertyInfoRequesting = useAppSelector(selectIsPropertyInfoRequesting);
  const propertyInfo = useAppSelector(selectPropertyInfo);
  const propertyPredictedPricePerMeter = useAppSelector(selectPropertyPredictedPrice);
  const propertyPredictionParameters = useAppSelector(selectPropertyPredictionParameters);
  const propertyPredictionType = useAppSelector(selectPropertyPredictionType);

  const isContentAvailable = useMemo(() => propertyPredictionType === PredictionTypes.PAID, [propertyPredictionType]);

  const propertyPredictedPriceFull = useMemo(() => {
    if (propertyPredictedPricePerMeter && propertyPredictionParameters.procedureArea) {
      return propertyPredictedPricePerMeter * propertyPredictionParameters.procedureArea;
    }

    return null;
  }, [propertyPredictedPricePerMeter, propertyPredictionParameters.procedureArea]);

  useEffect(() => {
    if (!id) {
      navigate(Paths.PROPERTY_PREDICTION_SELECT_PROJECT);
    }
  }, [id]);

  useEffect(() => {
    if (!accessToken) {
      return;
    }

    if (!id) {
      return;
    }

    dispatch(getPropertyInfoThunk({
      id,
      accessToken,
    }));
  }, [id]);

  useEffect(() => {
    sendAmplitudeEvent(AmplitudeEvents.PREDICTION_VIEWED);
  }, []);

  return (
    <div className="Prediction">
      <Container className="Prediction__section">
        <Row className="mb-3">
          <Col>
            <NavLink to={Paths.PROPERTY_PREDICTION_SELECT_PROJECT} className="Prediction__backButton">
              <i className="bi bi-arrow-left pe-2" />
              <span>Try again</span>
            </NavLink>
          </Col>
        </Row>
        <Row className="mb-3">
          <Col lg={8}>
            <h1 className="Prediction__title mb-2">
              {
                isPropertyInfoRequesting
                  ? <Skeleton width={350} />
                  : (
                    <>
                      <span className="Prediction__titleName">{ propertyInfo.projectName }</span>
                      <span className="Prediction__titleRooms">{ getEnumKeyByValue(PropertyRoomsTypes, propertyPredictionParameters.rooms) }</span>
                    </>
                  )
              }
            </h1>
            <div className="Prediction__parameters">
              {
                isPropertyInfoRequesting
                  ? <Skeleton width={200} />
                  : (
                    <div className="Prediction__parametersItem">
                      <div className="Prediction__parametersItemValue">UAE</div>
                      <div className="Prediction__parametersItemValue">&nbsp;•&nbsp;</div>
                      <div className="Prediction__parametersItemValue">
                        { propertyInfo.masterProject }
                      </div>
                      <div className="Prediction__parametersItemValue">&nbsp;•&nbsp;</div>
                      <div className="Prediction__parametersItemKey">
                        { propertyInfo.buildingName }
                      </div>
                    </div>
                  )
              }
            </div>
          </Col>
          <Col lg={4}>
            <h1 className="Prediction__title mb-2">
              <div className="Prediction__titlePrice">{`~${propertyPredictedPriceFull ? getFormattedNumber(propertyPredictedPriceFull) : null} AED`}</div>
            </h1>
            <div className="Prediction__pricePerMeter">
              <div>{`~${propertyPredictedPricePerMeter ? getFormattedNumber(propertyPredictedPricePerMeter) : null} AED / m²`}</div>
            </div>
          </Col>
        </Row>
        <Row className="mb-3">
          <Col>
            <div className="Prediction__photos">
              <div className="Prediction__photosItem" style={{ backgroundImage: 'url(https://blog.allplan.com/hubfs/EN_Blog/Architecture/Burj%20Khalifa%20-%20The%20Pinnacle%20of%20Architectural%20Excellence/iStock-183346577_NEU.jpg)' }} />
              <div className="Prediction__photosItem" style={{ backgroundImage: 'url(https://cf.bstatic.com/xdata/images/hotel/max1024x768/464246124.jpg?k=17ef3d5074ab2f5ce94bb9130400893bca45d30448e56d03b45457be3b7b669c&o=&hp=1)' }} />
              <div className="Prediction__photosItem" style={{ backgroundImage: 'url(https://cf.bstatic.com/xdata/images/hotel/max1024x768/408940874.jpg?k=ac57bd4668daef9a43c54434328b202a10e85ece559b494345f1c6997414e10a&o=&hp=1)' }} />
            </div>
          </Col>
        </Row>
        <Row className="Prediction__content">
          <Col lg={6} className="pe-lg-5">
            <div className="Prediction__contentBlock">
              <h3 className="Prediction__contentBlockTitle">
                Property Parameters
              </h3>
              <div className="Prediction__contentBlockParameters">
                <div className="Prediction__contentBlockParametersItem">
                  <div className="Prediction__contentBlockParametersItemKey">Rooms Type:</div>
                  <div className="Prediction__contentBlockParametersItemValue">{ getEnumKeyByValue(PropertyRoomsTypes, propertyPredictionParameters.rooms) }</div>
                </div>
                <div className="Prediction__contentBlockParametersItem">
                  <div className="Prediction__contentBlockParametersItemKey">Registration Type:</div>
                  <div className="Prediction__contentBlockParametersItemValue">{ getEnumKeyByValue(PropertyRegistrationTypes, propertyPredictionParameters.registrationType) }</div>
                </div>
                <div className="Prediction__contentBlockParametersItem">
                  <div className="Prediction__contentBlockParametersItemKey">Transaction Method:</div>
                  <div className="Prediction__contentBlockParametersItemValue">{getEnumKeyByValue(PropertyTransactionGroups, propertyPredictionParameters.transactionGroup) }</div>
                </div>
                <div className="Prediction__contentBlockParametersItem">
                  <div className="Prediction__contentBlockParametersItemKey">Area:</div>
                  <div className="Prediction__contentBlockParametersItemValue">{ propertyPredictionParameters.procedureArea }</div>
                </div>
                <div className="Prediction__contentBlockParametersItem">
                  <div className="Prediction__contentBlockParametersItemKey">Parking:</div>
                  <div className="Prediction__contentBlockParametersItemValue">{ propertyPredictionParameters.hasParking ? 'Yes' : 'No' }</div>
                </div>
              </div>
            </div>
            <div className="Prediction__contentBlock">
              <h3 className="Prediction__contentBlockTitle">
                Property Description
              </h3>
              <p>
                Develop an informed understanding about topics that matter to you. Enjoy our analysis of the topics that matter most to you. Fair-minded. Expert analysis. Global perspective. Develop an informed understanding about topics that matter to you.
              </p>
            </div>
            <div className="Prediction__contentBlock">
              <h3 className="Prediction__contentBlockTitle">
                Infrastructure
              </h3>
              {
                !isContentAvailable ? (
                  <ContentPlug />
                ) : (
                  <div className="Prediction__contentBlockParameters">
                    <div className="Prediction__contentBlockParametersItem">
                      <div className="Prediction__contentBlockParametersItemKey">&nbsp;</div>
                      <div className="Prediction__contentBlockParametersItemValue Prediction__contentBlockParametersItemValue--accent">
                        <i className="bi bi-person-walking" />
                      </div>
                      <div className="Prediction__contentBlockParametersItemValue Prediction__contentBlockParametersItemValue--accent">
                        <i className="bi bi-car-front-fill" />
                      </div>
                    </div>
                    <div className="Prediction__contentBlockParametersItem">
                      <div className="Prediction__contentBlockParametersItemKey">Distance to Metro:</div>
                      <div className="Prediction__contentBlockParametersItemValue">
                        { isPropertyInfoRequesting ? <Skeleton width={100} /> : `${propertyInfo.walkingDistanceToMetro ? getFormattedNumber(propertyInfo.walkingDistanceToMetro, 0) : null} m.` }
                      </div>
                      <div className="Prediction__contentBlockParametersItemValue">N/A</div>
                    </div>
                    <div className="Prediction__contentBlockParametersItem">
                      <div className="Prediction__contentBlockParametersItemKey">Distance to Healthcare:</div>
                      <div className="Prediction__contentBlockParametersItemValue">
                        { isPropertyInfoRequesting ? <Skeleton width={100} /> : `${propertyInfo.walkingDistanceToHealthcare ? getFormattedNumber(propertyInfo.walkingDistanceToHealthcare, 0) : null}  m.` }
                      </div>
                      <div className="Prediction__contentBlockParametersItemValue">
                        { isPropertyInfoRequesting ? <Skeleton width={100} /> : `${propertyInfo.drivingDistanceToHealthcare ? getFormattedNumber(propertyInfo.drivingDistanceToHealthcare, 0) : null}  m.` }
                      </div>
                    </div>
                    <div className="Prediction__contentBlockParametersItem">
                      <div className="Prediction__contentBlockParametersItemKey">Distance to Downtown:</div>
                      <div className="Prediction__contentBlockParametersItemValue">
                        { isPropertyInfoRequesting ? <Skeleton width={100} /> : `${propertyInfo.walkingDistanceToDowntown ? getFormattedNumber(propertyInfo.walkingDistanceToDowntown, 0) : null}  m.` }
                      </div>
                      <div className="Prediction__contentBlockParametersItemValue">
                        { isPropertyInfoRequesting ? <Skeleton width={100} /> : `${propertyInfo.drivingDistanceToDowntown ? getFormattedNumber(propertyInfo.drivingDistanceToDowntown, 0) : null}  m.` }
                      </div>
                    </div>
                    <div className="Prediction__contentBlockParametersItem">
                      <div className="Prediction__contentBlockParametersItemKey">Distance to Fountains:</div>
                      <div className="Prediction__contentBlockParametersItemValue">
                        { isPropertyInfoRequesting ? <Skeleton width={100} /> : `${propertyInfo.walkingDistanceToDfountain ? getFormattedNumber(propertyInfo.walkingDistanceToDfountain, 0) : null}  m.` }
                      </div>
                      <div className="Prediction__contentBlockParametersItemValue">
                        { isPropertyInfoRequesting ? <Skeleton width={100} /> : `${propertyInfo.drivingDistanceToDfountain ? getFormattedNumber(propertyInfo.drivingDistanceToDfountain, 0) : null}  m.` }
                      </div>
                    </div>
                    <div className="Prediction__contentBlockParametersItem">
                      <div className="Prediction__contentBlockParametersItemKey">Distance to Beach:</div>
                      <div className="Prediction__contentBlockParametersItemValue">
                        { isPropertyInfoRequesting ? <Skeleton width={100} /> : `${propertyInfo.walkingDistanceToBeach ? getFormattedNumber(propertyInfo.walkingDistanceToBeach, 0) : null}  m.` }
                      </div>
                      <div className="Prediction__contentBlockParametersItemValue">
                        { isPropertyInfoRequesting ? <Skeleton width={100} /> : `${propertyInfo.drivingDistanceToBeach ? getFormattedNumber(propertyInfo.drivingDistanceToBeach, 0) : null}  m.` }
                      </div>
                    </div>
                    <div className="Prediction__contentBlockParametersItem">
                      <div className="Prediction__contentBlockParametersItemKey">Distance to Business Bay:</div>
                      <div className="Prediction__contentBlockParametersItemValue">
                        { isPropertyInfoRequesting ? <Skeleton width={100} /> : `${propertyInfo.walkingDistanceToBbay ? getFormattedNumber(propertyInfo.walkingDistanceToBbay, 0) : null}  m.` }
                      </div>
                      <div className="Prediction__contentBlockParametersItemValue">
                        { isPropertyInfoRequesting ? <Skeleton width={100} /> : `${propertyInfo.drivingDistanceToBbay ? getFormattedNumber(propertyInfo.drivingDistanceToBbay, 0) : null}  m.` }
                      </div>
                    </div>
                  </div>
                )
              }
            </div>
            <div className="Prediction__contentBlock">
              <h3 className="Prediction__contentBlockTitle">
                Location
              </h3>
              <p>
                { isPropertyInfoRequesting ? <Skeleton width={150} /> : `UAE, ${propertyInfo.masterProject}, ${propertyInfo.buildingName}, ${propertyInfo.projectName}` }
              </p>
            </div>
          </Col>
          <Col lg={6}>
            <div className="Prediction__contentBlock">
              <h3 className="Prediction__contentBlockTitle">
                Predicted Price
              </h3>
              <div className="Prediction__contentBlockParameters">
                <div className="Prediction__contentBlockParametersItem">
                  <div className="Prediction__contentBlockParametersItemKey">Full Price:</div>
                  <div className="Prediction__contentBlockParametersItemValue">{`~${propertyPredictedPriceFull ? getFormattedNumber(propertyPredictedPriceFull) : null} AED`}</div>
                </div>
                <div className="Prediction__contentBlockParametersItem">
                  <div className="Prediction__contentBlockParametersItemKey">Price per m²:</div>
                  <div className="Prediction__contentBlockParametersItemValue">{`~${propertyPredictedPricePerMeter ? getFormattedNumber(propertyPredictedPricePerMeter) : null} AED / m²`}</div>
                </div>
              </div>
            </div>
            <div className="Prediction__contentBlock">
              <h3 className="Prediction__contentBlockTitle">
                Price Formation
              </h3>
              <p>
                The price of real estate depends on many factors. Below are the factors that influence the formation of the price of an object:
              </p>
              {
                !isContentAvailable ? (
                  <ContentPlug />
                ) : (
                  <div className="Prediction__contentBlockParameters">
                    <div className="Prediction__contentBlockParametersItem">
                      <div className="Prediction__contentBlockParametersItemKey">Base price:</div>
                      <div className="Prediction__contentBlockParametersItemValue">27 340 AED / m²</div>
                    </div>
                    <div className="Prediction__contentBlockParametersItem">
                      <div className="Prediction__contentBlockParametersItemKey">Area:</div>
                      <div className="Prediction__contentBlockParametersItemValue Prediction__contentBlockParametersItemValue--negative">+7 410 AED / m²</div>
                    </div>
                    <div className="Prediction__contentBlockParametersItem">
                      <div className="Prediction__contentBlockParametersItemKey">Parking:</div>
                      <div className="Prediction__contentBlockParametersItemValue Prediction__contentBlockParametersItemValue--negative">+1 410 AED / m²</div>
                    </div>
                    <div className="Prediction__contentBlockParametersItem">
                      <div className="Prediction__contentBlockParametersItemKey">Transaction Method:</div>
                      <div className="Prediction__contentBlockParametersItemValue Prediction__contentBlockParametersItemValue--positive">–2 620 AED / m²</div>
                    </div>
                    <div className="Prediction__contentBlockParametersItem">
                      <div className="Prediction__contentBlockParametersItemKey">Distance to Beach:</div>
                      <div className="Prediction__contentBlockParametersItemValue Prediction__contentBlockParametersItemValue--negative">+2 410 AED / m²</div>
                    </div>
                    <div className="Prediction__contentBlockParametersItem">
                      <div className="Prediction__contentBlockParametersItemKey">Distance to Metro:</div>
                      <div className="Prediction__contentBlockParametersItemValue Prediction__contentBlockParametersItemValue--positive">–1 350 AED / m²</div>
                    </div>
                    <div className="Prediction__contentBlockParametersItem">
                      <div className="Prediction__contentBlockParametersItemKey">Other factors:</div>
                      <div className="Prediction__contentBlockParametersItemValue Prediction__contentBlockParametersItemValue--positive">–705 AED / m²</div>
                    </div>
                    <div className="Prediction__contentBlockParametersItem">
                      <div className="Prediction__contentBlockParametersItemKey">Final price:</div>
                      <div className="Prediction__contentBlockParametersItemValue">{`~${propertyPredictedPricePerMeter ? getFormattedNumber(propertyPredictedPricePerMeter) : null} AED / m²`}</div>
                    </div>
                  </div>
                )
              }
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};
