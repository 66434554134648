import React, { FC, useCallback } from 'react';
import { NavLink } from 'react-router-dom';
import {
  Navbar,
  NavbarBrand,
  Container,
  Nav,
} from 'react-bootstrap';

import { useAppDispatch, useAppSelector } from '../../store';
import { selectAccessToken, signOut } from '../../store/auth.reducer';
import logo from '../../assets/logo.svg';
import { Paths } from '../../constants';

import './Header.css';

export const Header: FC = () => {
  const dispatch = useAppDispatch();
  const accessToken = useAppSelector(selectAccessToken);
  const signOutHandler = useCallback(() => {
    dispatch(signOut());
  }, []);

  return (
    <Navbar expand="lg" className="Header">
      <Container>
        <NavbarBrand href={Paths.MAIN}>
          <img src={logo} alt="Logo" height="18" />
        </NavbarBrand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        {
          accessToken === null
            ? (
              <Navbar.Collapse id="basic-navbar-nav">
                <Nav className="m-auto">
                  <NavLink to={Paths.MAIN} className="Header__navLink nav-link">Main</NavLink>
                  <Nav.Link href={`${Paths.MAIN}#Pricing`} className="Header__navLink nav-link">Pricing</Nav.Link>
                  <Nav.Link href={`${Paths.MAIN}#FAQ`} className="Header__navLink nav-link">FAQ</Nav.Link>
                </Nav>
                <Nav className="ml-auto">
                  <NavLink to={Paths.AUTH} className="nav-link">Sign-in • Sign-up</NavLink>
                </Nav>
              </Navbar.Collapse>
            ) : (
              <Navbar.Collapse id="basic-navbar-nav">
                <Nav className="m-auto">
                  <NavLink to={Paths.PROPERTY_PREDICTION_SELECT_PROJECT} className="Header__navLink nav-link">Get Prediction</NavLink>
                  <NavLink to={Paths.PAYMENT_PACKS} className="Header__navLink nav-link">Top Up Balance</NavLink>
                </Nav>
                <Nav className="ml-auto">
                  <NavLink to={Paths.ACCOUNT_PROFILE} className="nav-link">Account</NavLink>
                  <NavLink to={Paths.MAIN} className="nav-link" onClick={signOutHandler}>Sign-out</NavLink>
                </Nav>
              </Navbar.Collapse>
            )
        }
      </Container>
    </Navbar>
  );
};
